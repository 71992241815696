import { parse } from 'bowser';
import { locale } from '../studyteam/invite/studyteam-invite';

// get browser information
export const getBrowserInfo = () => {
  const { userAgent } = window.navigator;
  const { browser, os, platform } = parse(userAgent);
  const host = window.location.host;
  const currentUrl = window.location.href;
  const language = locale.split('-')[0];

  return {
    browser: browser.name,
    browserVersion: browser.version,
    device: platform.type,
    host,
    os: os.name,
    osVersion: os.version,
    url: currentUrl,
    locale,
    language,
  };
};
