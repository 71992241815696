import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getBrowserInfo } from './browser';
import { integrationProdUrls } from '../studyteam/invite/studyteam-invite';

const api = axios.create({
  baseURL: 'https://api-eu.mixpanel.com',
  headers: {
    accept: 'text/plain',
    // needed to prevent a preflight request that was causing CORS errors
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const trackAnonymousEvent = async (eventName, properties) => {
  let mixpanelId;
  if (integrationProdUrls.includes(window.location.hostname)) {
    mixpanelId = '66ee5be14517791e2f8aee56dbce1cb6';
  } else {
    mixpanelId = 'c26e81980d997ada29c599b4f51e22a9';
  }

  const queryParams = new URLSearchParams(window.location.search);
  // get UTM params from query params
  const utmCodes = {
    utm_source: queryParams.get('utm_source') || undefined,
    utm_medium: queryParams.get('utm_medium') || undefined,
    utm_campaign: queryParams.get('utm_campaign') || undefined,
    utm_content: queryParams.get('utm_content') || undefined,
    utm_term: queryParams.get('utm_term') || undefined,
  };

  const params = new URLSearchParams();
  params.append(
    'data',
    JSON.stringify({
      event: eventName,
      properties: {
        ...properties,
        token: mixpanelId,
        distinct_id: '',
        $insert_id: uuidv4(),
        time: Date.now(),
        eventType: 'anonymous',
        ...utmCodes,
        ...getBrowserInfo(),
      },
    }),
  );

  const response = await api.post('/track', params);
  return response.data;
};

/**
 * Retrieve all UTM parameters
 */
export const getUtmParameters = () => {
  const params = new URLSearchParams(window.location.search);

  const utmParams = {
    utm_parameters: Array.from(params).reduce((acc, param) => {
      let [key, val] = param;
      key = key.replace(/^utm/i, 'utm');
      if (key.startsWith('utm')) {
        return acc[key]
          ? { ...acc, [key]: [...acc[key], val] }
          : { ...acc, [key]: [val] };
      }
      return acc;
    }, Object()),
  };
  return utmParams;
};
