import { v4 as uuid } from 'uuid';

export const integrationProdUrls = [
  'www.merckclinicaltrials.com',
  'merckclinicaltrials.com',
  'www.msdclinicaltrials.com',
  'msdclinicaltrials.com',
];
const sessionId = uuid();

import i18next from 'i18next';

export const locale = document.currentScript.getAttribute('locale') || 'en-US';

import en_US_translations from '../../../locales/en-US/translation.json';
import es_US_translations from '../../../locales/es-US/translation.json';
import { trackAnonymousEvent } from '../../utils/analytics';

const translationResources = {
  'en-US': {
    translation: en_US_translations,
  },
  'es-US': {
    translation: es_US_translations,
  },
};

i18next.init(
  {
    lng: locale, // if you're using a language detector, do not define the lng option
    debug: true,
    returnObjects: true,
    fallbackLng: 'en-US',
    load: 'currentOnly',
    resources: translationResources,
    interpolation: {
      escapeValue: false,
    },
  },
  translationsReady,
);

export function studyteamChangeLanguage(languageLocale, callback) {
  return i18next.changeLanguage(languageLocale, (err, t) => {
    if (err) {
      console.log('Error changing language: ', err);
    } else {
      for (var elem of document.getElementsByTagName('studyteam-button')) {
        elem.dispatchEvent(
          new CustomEvent('languageChanged', {
            detail: { newT: t },
          }),
        );
      }
      for (var elem of document.getElementsByTagName('studyteam-form')) {
        elem.dispatchEvent(
          new CustomEvent('languageChanged', {
            detail: { newT: t, locale: i18next.language },
          }),
        );
      }
      return t;
    }
  });
}

function translationsReady(err, t) {
  for (var elem of document.getElementsByTagName('studyteam-form')) {
    elem.dispatchEvent(
      new CustomEvent('languageChanged', {
        detail: { newT: t },
      }),
    );
  }
  window.i18next = i18next;

  function loadGoogleTagManager(containerId) {
    let script = document.createElement('script');
    //TODO: update this to also specify gtm environment level
    script.textContent = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${containerId}');
    `;
    document.getElementsByTagName('head')[0].prepend(script);

    let noScript = document.createElement('noscript');
    let iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `https://googletagmanager.com/ns.html?id=${containerId}`,
    );
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');
    noScript.appendChild(iframe);
    document.body.prepend(noScript);
  }

  function getUtmParameters() {
    const params = new URLSearchParams(window.location.search);
    const utmQueryString = Array.from(params).reduce((acc, [key, val]) => {
      key = key.replace(/^utm/i, 'utm');
      if (key.startsWith('utm')) {
        return acc == '' ? `${key}=${val}` : `${acc}&${key}=${val}`;
      }
      return acc;
    }, '');

    return utmQueryString;
  }

  class StudyteamButton extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      this.shadow = this.attachShadow({ mode: 'open' });

      this.micrositeId = this.getAttribute('micrositeId');
      this.sponsorSiteId = this.getAttribute('siteId');
      this.trialId = this.getAttribute('trialId');
      //this.locale = this.getAttribute('locale');

      if (!this.micrositeId) {
        console.error('micrositeId parameter required');
        return;
      }

      if (!this.sponsorSiteId) {
        console.error('siteId parameter required');
        return;
      }

      if (!this.trialId) {
        console.error('trialId parameter required');
        return;
      }

      // This gets populated from the get sites response
      this.siteId = null;

      // Get Sites url
      if (integrationProdUrls.includes(window.location.hostname)) {
        this.getSitesUrl = `https://api.studyteamapp.com/microsites-api/v1/${this.micrositeId}/sites/${this.trialId}`;
      } else {
        this.getSitesUrl = `https://us.staging.api.studyteamapp.com/microsites-api/v1/${this.micrositeId}/sites/${this.trialId}`;
      }

      this.classList.add('gtm-trigger-open-modal');

      this.button = document.createElement('button');
      this.button.setAttribute('class', 'studyteam-custom-button');
      this.button.textContent = i18next.t('studyteamButton.buttonText'); //this.textContent;
      this.addEventListener('languageChanged', (e) => {
        this.button.textContent = e.detail.newT('studyteamButton.buttonText');
      });

      this.styles = document.createElement('style');
      this.styles.textContent = `
        .studyteam-custom-button {
          display: none;
          font-family: sans-serif!important;
          background-image: none;
          box-shadow: none;
          box-sizing: border-box;
          appearance: none;
          font: inherit;
          border: 0;
          cursor: pointer;
          text-decoration: none;
          font-weight: bold;
          border-radius: 3px;
          background: rgb(0, 133, 124);
          color: rgb(236 232 229);
          -webkit-transition: background .125s linear, color .125s linear;
          transition: background .125s linear, color .125s linear;
          padding: 0.25rem 0.5rem;
          margin-top: 1rem;
          font-size: 0.875rem;
        }
        .studyteam-custom-button:hover,
        .studyteam-custom-button:focus {
          background-color: rgb(0, 133, 124);
          outline: none;
        }
        .studyteam-custom-button.is--visible { display: block; }  
      `;

      this.shadow.appendChild(this.styles);
      this.shadow.appendChild(this.button);

      this.form = document.querySelector('studyteam-form');
      this.button.addEventListener('click', this.onButtonClick.bind(this));

      if (!StudyteamButton.leader) {
        StudyteamButton.leader = this;
      }

      this.fetchApi();
    }

    disconnectedCallback() {
      // make sure we remove the old leader when it's removed from the DOM
      // or else new buttons won't display
      if (StudyteamButton.leader === this) {
        StudyteamButton.leader = null;
      }
    }

    openModal() {
      window.dataLayer.push({
        event: 'gtmOnStudyteamOpenModal',
        detail: {
          id: sessionId,
        },
      });
      trackAnonymousEvent('gtmOnStudyteamOpenModal', {
        micrositeId: this.micrositeId,
        siteId: this.siteId,
        trialId: this.trialId,
        sponsorSiteId: this.sponsorSiteId,
      });
    }

    onButtonClick(e) {
      e.preventDefault();

      this.openModal();
      this.form.dispatchEvent(
        new CustomEvent('siteIdMatch', {
          bubbles: true,
          detail: {
            micrositeId: this.micrositeId,
            siteId: this.siteId,
            trialId: this.trialId,
            sponsorSiteId: this.sponsorSiteId,
          },
        }),
      );
      this.form.wrapper.classList.add('is--visible');
    }

    fetchApi() {
      if (StudyteamButton.leader !== this) {
        document.addEventListener('studyteamApiResponse', (e) =>
          this.matchSites(e.detail.res),
        );
        return;
      }

      fetch(this.getSitesUrl, { mode: 'cors' })
        .then((res) => {
          if (res.status >= 200 && res.status < 400) {
            return res.json();
          }
          if (res.status >= 400 && res.status < 500) {
            console.warn('Studyteam API bad request');
            return;
          }
          if (res.status >= 500) {
            console.error('Studyteam API Internal Server Error');
            return;
          }
        })
        .then((res) => {
          document.dispatchEvent(
            new CustomEvent('studyteamApiResponse', {
              bubbles: true,
              detail: { res: res },
            }),
          );
          this.matchSites(res);
        })
        .catch((e) => {
          console.error('Error fetching Studyteam sites');
        });
    }

    matchSites(sites) {
      if (!sites || sites.length === 0) {
        console.warn('No sites availables');
        return;
      }

      sites.map((site) => {
        if (site.sponsorSiteId === this.sponsorSiteId) {
          this.button.classList.add('is--visible');
          this.siteId = site.siteId;
        }
      });
    }
  }

  StudyteamButton.leader = null;

  class StudyteamForm extends HTMLElement {
    constructor() {
      super();
      this.shadow = this.attachShadow({ mode: 'open' });
      //TODO: update gtm id
      this.gtmContainerId = '';
    }

    connectedCallback() {
      if (integrationProdUrls.includes(window.location.hostname)) {
        this.isTestingEnv = false;
        this.domain = 'https://merckctf.studyteamapp.com';
      } else if (window.location.hostname === 'localhost') {
        this.domain = window.location.origin;
        this.isTestingEnv = true;
      } else {
        this.isTestingEnv = true;
        this.domain = 'https://staging.merckctf.studyteamapp.com';
      }
      this.locale = locale;
      this.wrapper = document.createElement('div');
      this.wrapper.setAttribute('class', 'reify-form-iframe-wrapper');

      this.wrapperInner = document.createElement('section');
      this.wrapperInner.setAttribute(
        'class',
        'reify-form-iframe-wrapper-inner',
      );

      this.iframeContainer = document.createElement('div');
      this.iframeContainer.setAttribute('class', 'reify-form-iframe-container');

      this.close = document.createElement('header');
      this.close.setAttribute('class', 'reify-form-close');

      this.closeText = document.createElement('h1');
      this.closeText.setAttribute('class', 'reify-form-close-text');
      this.closeText.textContent = i18next.t('moctForm.ui.headerTitle');

      this.closeButton = document.createElement('button');
      this.closeButton.setAttribute(
        'class',
        'reify-form-close-btn gtm-trigger-close-modal',
      );
      this.closeButton.setAttribute('role', 'button');
      this.closeButton.setAttribute('aria-label', 'Close');
      this.closeButton.textContent = 'x';
      this.closeButton.addEventListener('click', this.closeModal.bind(this));

      this.close.appendChild(this.closeText);
      this.close.appendChild(this.closeButton);

      this.styles = document.createElement('style');
      this.styles.textContent = `
        .reify-form-iframe-wrapper {
          box-sizing: border-box;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 14px;
          display: none;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0;
          margin: 0;
          z-index: 10000000000;
          background-color: rgba(255, 255, 255, 0.5);
        }
        .reify-form-iframe-wrapper.is--visible {
          display: block;
        }
        .reify-form-iframe-wrapper-inner {
          box-sizing: border-box;
          width: 90%;
          max-width: 800px;
          margin: 80px auto 0 auto;
          background-color: rgb(240,240,240);
          box-shadow: 0 4px 16px rgba(0,0,0,0.25);
          border: 1px solid rgb(210,210,210);
          border-radius: 6px;
        }
        .reify-form-iframe-container {
          box-sizing: border-box;
          height: calc(100% - 60px);
        }
        .reify-form-close {
          height: 60px;
          box-sizing: border-box;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          background-color: rgb(240, 240, 240);
          border-bottom: 1px solid rgb(210,210,210);
        }
        .reify-form-close-text {
          font-weight: 700;
          font-size: 18px;
          color: rgb(30, 30, 30);
          margin: 0;
          padding: 0;
        }
        .reify-form-close-btn {
          text-transform: uppercase;
          color: rgb(130,130,130);
          font-weight: 600;
          font-size: 16px;
          font-family: sans-serif;
          background-color: unset;
          border: none;
          border-radius: 3px;
          padding: 0.35rem 0.7rem;
        }
        .reify-form-close-btn:hover,
        .reify-form-close-btn:focus {
          cursor: pointer;
          background-color: rgb(210,210,210);
          outline: none;
        }
        .reify-form-iframe {
          height: 70vh;
          max-height: 750px;
          border: none;
        }
      `;

      this.shadow.appendChild(this.styles);
      this.shadow.appendChild(this.wrapper);
      this.wrapper.appendChild(this.wrapperInner);
      this.wrapperInner.appendChild(this.close);
      this.wrapperInner.appendChild(this.iframeContainer);

      loadGoogleTagManager(this.gtmContainerId);

      this.addEventListener('siteIdMatch', this.onSiteIdMatch.bind(this));
      this.addEventListener('languageChanged', (e) => {
        this.locale = e.detail.locale;
        this.closeText.textContent = e.detail.newT('moctForm.ui.headerTitle');
        window.dispatchEvent(
          new CustomEvent('languageChanged', {
            detail: { newT: e.detail.newT },
          }),
        );
        document.dispatchEvent(
          new CustomEvent('languageChanged', {
            detail: { newT: e.detail.newT },
          }),
        );
      });

      window.addEventListener(
        'message',
        (e) => {
          window.dataLayer = window.dataLayer || [];
          if (e.data.event === 'closeModal') {
            this.closeModal(e);
          } else if (e.data.event === 'submitForm') {
            this.registerSubmitFormToGTM(e);
          }
        },
        false,
      );
    }

    closeModal(e) {
      e.preventDefault();
      this.formIframe.remove();
      this.wrapper.classList.remove('is--visible');
      window.dataLayer.push({
        event: 'gtmOnStudyteamCloseModal',
        detail: {
          id: sessionId,
        },
      });
      trackAnonymousEvent('gtmOnStudyteamCloseModal', {
        micrositeId: this.micrositeId,
        siteId: this.siteId,
        trialId: this.trialId,
        sponsorSiteId: this.sponsorSiteId,
      });
    }

    registerSubmitFormToGTM(e) {
      window.dataLayer.push({
        event: 'gtmOnStudyteamSubmitSuccessful',
        detail: {
          id: sessionId,
        },
        //Object.assign(e.data.detail,{id: sessionId})
      });
      trackAnonymousEvent('gtmOnStudyteamSubmitSuccessful', {
        micrositeId: this.micrositeId,
        siteId: this.siteId,
        trialId: this.trialId,
        sponsorSiteId: this.sponsorSiteId,
      });
    }

    onSiteIdMatch(e) {
      this.micrositeId = e.detail.micrositeId;
      this.siteId = e.detail.siteId;
      this.trialId = e.detail.trialId;
      this.sponsorSiteId = e.detail.sponsorSiteId;
      this.formIframe = document.createElement('iframe');
      const utm_parameters = getUtmParameters();
      this.formIframe.src = `${this.domain}/studyteam-form.html?isTestingEnv=${this.isTestingEnv}&micrositeid=${this.micrositeId}&siteid=${this.siteId}&trialid=${this.trialId}&sponsorsiteid=${this.sponsorSiteId}&locale=${this.locale}&${utm_parameters}`;
      this.formIframe.setAttribute('width', '100%');
      this.formIframe.setAttribute('class', 'reify-form-iframe');
      this.formIframe.setAttribute('title', 'Trial Form');
      this.iframeContainer.appendChild(this.formIframe);
    }
  }

  customElements.define('studyteam-form', StudyteamForm, {});
  customElements.define('studyteam-button', StudyteamButton, {});
}
